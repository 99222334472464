import React from 'react'
import "./mobileSoon.css";
import mobileBeefLogo from "../../images/mobileBeefLogo.png"
import mobileCheeseLogo from "../../images/mobileCheeseLogo.png"

const MobileComingSoon = () => {
  return (
    <div className="mobileComingSoon">
            <img src={mobileBeefLogo} alt="Beef Team Logo" className="beefImg"/>

            <div>
                  <div className="text text1">Glasanje počinje</div>
                  <div className="text text2">12. 3. <br/> 2025. godine.</div>     
                  <div className="text text3">Uskoro više informacija.</div>    
            </div>

            <img src={mobileCheeseLogo} alt="Cheese Team Logo" className='cheeseImg'/>
    </div>
  )
}

export default MobileComingSoon