import React from 'react'
import "./banner.css"
import bannerBackground from "../../images/bannerBackground.png"
import serbiaFlag from "../../images/serbiaFlag.png"
import bosniaFlag from "../../images/bosniaFlag.png"
import macedoniaFlag from "../../images/macedoniaFlag.png"
import montenegroFlag from "../../images/montenegroFlag.png"

const Banner = () => {
  return (
    <div className="bannerPage">
      <h1>CHOOSE COUNTRY</h1>

      <div className="allFlags">

        <div className="flagContainer">
          <a href="https://prelive-rs.izaberitim.com/">
            <img src={serbiaFlag} alt="Serbia Flag" />
          </a>

          <div className="countryName">SERBIA</div>
        </div>

        <div className="flagContainer">
          <a href="https://prelive-ba.izaberitim.com/">
            <img src={bosniaFlag} alt="Bosnia Flag" />
          </a>
          <div className="countryName">BOSNIA</div>
        </div>

        <div className="flagContainer">
          <a>
            <img src={montenegroFlag} alt="Montenegro Flag" className="disabled" />
          </a>
          <div className="countryName disabled">MONTENEGRO</div>
        </div>

        <div className="flagContainer">
          <a>
            <img src={macedoniaFlag} alt="Macedonia Flag" className="disabled" />
          </a>
          <div className="countryName disabled">MACEDONIA</div>
        </div>

      </div>
    </div>
  )
}

export default Banner