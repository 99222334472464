import React from 'react'
import "./header.css";
import chipsyLogo from "../../images/chipsyLogo.png";
import { NavLink } from 'react-router-dom';

const Header = () => {
  return (
    <div className="header">
            <img src={chipsyLogo} alt="Chipsy Logo"/>

            <nav>
                    <NavLink to="/vote"> GLASAJ </NavLink>
                    <NavLink to="/rules"> PRAVILA </NavLink>
                    <NavLink to="/gifts"> POKLONI </NavLink>
                    <NavLink to="/winners"> POBEDNICI </NavLink>
                    <NavLink to="/q&a"> Q&A </NavLink>
                    <NavLink to="/contact"> KONTAKT </NavLink>
            </nav>
    </div>
  )
}

export default Header