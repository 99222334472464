import React from 'react'
import "./vote.css";
import beefLogo from "../../images/beefLogo.png";
import cheeseLogo from "../../images/cheeseLogo.png";
import beefChipsy from "../../images/beefChipsy.png";
import cheeseChipsy from "../../images/cheeseChipsy.png";
import chooseTeam from "../../images/chooseTeam.png";


const Vote = () => {
  return (
    <div className="votePage">
            <img src={beefLogo} alt="Beef Logo"/>
            <img src={beefChipsy} alt="Beef Chipsy" className="beefChipsy"/>
            <img src={chooseTeam} alt="Choose Team" className="chooseTeam"/>
            <img src={cheeseChipsy} alt="Cheese Team" className="cheeseTeam"/>
            <img src={cheeseLogo} alt="Cheese Logo"/>
    </div>
  )
}

export default Vote