import React from 'react'
import "./soon.css"
import beefLogo from "../../images/beefLogo.png"
import cheeseLogo from "../../images/cheeseLogo.png"

const ComingSoon = () => {
  return (
    <div className="comingSoon">
            <div className="logoContainer">
                <img src={beefLogo} alt="Beef Team Logo" className="beefImg"/>
                <img src={cheeseLogo} alt="Cheese Team Logo" className='cheeseImg'/>
            </div>

            <div>
                  <div className="text text1">Glasanje počinje</div>
                  <div className="text text2">12. 3. 2025. godine.</div>     
                  <div className="text text3">Uskoro više informacija.</div>    
            </div>
    </div>
  )
}

export default ComingSoon