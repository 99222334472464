import React from 'react'
import "./prizes.css";
import scoreBoard from "../../images/scoreBoard.png";
import prizesDaily from "../../images/prizesDaily.png"
import prizesWeekly from "../../images/prizesWeekly.png";
import prizesMonthly from "../../images/prizesMonthly.png";
import prizesMain from "../../images/prizesMain.png";

const Prizes = () => {
    return (
        <div className="prizes">
            <img src={scoreBoard} alt="ScoreBoard" className="scoreBoard" />

            <div className="allPrizes">
                <div className="prize prize1">

                    <img src={prizesDaily} alt="Daily Prize" />
                    Kako da osvojiš Easy Cheese  ili Beef Street majicu?
                    Glasaj za omiljeni ukus, a onda napiši pesmu i mozda bas ti budes jedan od nagradjenih.
                </div>

                <div className="prize prize2">

                    <img src={prizesWeekly} alt="Weekly Prize" />
                    Kako da poneseš kući ovaj strava hoodie? Lako, glasaš za Easy Cheese ili Beef Street ukus,
                    napišeš pesmu, osvojiš dnevnu nagradu i možda baš tebe izaberemo. Srećno!
                </div>

                <div className="prize prize3">

                    <img src={prizesMonthly} alt="Monthly Prize" />
                    Šta se strava slaže uz Easy Cheese i Beef Street majicu, naravno – Koledžica da je obučeš preko jer su noći još uvek hladne.
                    Glasaj za omiljeni ukus, napiši pesmu, osvoji dnevnu nagradu i možda baš ti na kraju meseca dobiješ priliku da uz strava majicu nosiš i strava jaknu.
                </div>

                <div className="prize prize4">

                    <img src={prizesMain} alt="Main Prize" />
                    A šta je glavna nagrada? Snimanje pesme sa našim brend ambasadorom. Tvoje je samo da glasaš za omiljeni ukus, napišeš pesmu, osvojiš
                    jednu od dnevnih nagrada i možeš da postaneš ona osoba koja priča "Kad smo Vojaž i ja snimali pesmu..." ili "Kad smo Amna i ja snimali pesmu..."
                </div>


            </div>
        </div>
    )
}

export default Prizes