
import './App.css';
import { Route , Routes } from 'react-router-dom';
import Banner from './components/bannerPage/banner';
import ComingSoon from './components/soonPage/soon';
import MobileComingSoon from './components/soonPage/mobileSoon';
import Header from './components/header/header';
import Vote from './components/votePage/vote';
import Prizes from './components/prizes/prizes';

const App = () => {


  let currentUrl = window.location.origin

  return (
    <div className="App">
      {currentUrl === "https://prelive.izaberitim.com" && <Banner />}

      {(currentUrl === "https://prelive-rs.izaberitim.com" || currentUrl === "https://prelive-ba.izaberitim.com") &&
        <>
          <ComingSoon />
          <MobileComingSoon />
        </>}

        <Header/>

        <Routes>
            <Route path="/vote" element={<Vote/>} />
            <Route path="/winners" element={<Prizes/>}/>
        </Routes>

    </div>
  );
}

export default App;
